<template>
  <div class="d-sm-flex align-items-center justify-content-end pt-4">
    <div class="my-2 my-md-0 mr-2">
      <b-pagination
        ref="pagination"
        v-model="currentPage"
        :per-page="pagination.perPage"
        :total-rows="totalRows"
        hide-ellipsis
        page-class="page"
        first-class="arrow-button"
        last-class="arrow-button"
        next-class="arrow-button"
        prev-class="arrow-button"
        class="app-pagination-pager mb-0"
        @change="changePage"
      ></b-pagination>
    </div>

    <div class="my-2 my-md-0">
      <b-dropdown
        size="sm"
        variant="secondary"
        :text="pageSizeValue.toString()"
        menu-class="dropdown-menu-new-class"
        class="mr-4 dropdown-new-class"
      >
        <template v-for="(i, index) in pageSizeItems">
          <b-dropdown-item
            :key="index"
            :active="pageSizeValue == i"
            @click="updatePageSize(i)"
            >{{ i }}</b-dropdown-item
          >
        </template>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
  import '@/assets/sass/components/_variables.bootstrap.scss';

  export default {
    name: 'AppTablePagination',
    props: {
      pagination: {
        type: Object,
        default: () => ({
          currentPage: 1,
          total: 1,
          perPage: 10,
          lastPage: 1
        })
      }
    },
    data: () => ({
      currentPage: 1,
      pageSizeItems: [10, 30, 50, 100],
      pageSizeValue: 10
    }),
    computed: {
      totalRows() {
        let p = this.pagination;

        return p.perPage * p.lastPage;
      }
    },
    watch: {
      'pagination.currentPage'() {
        if (this.pagination.currentPage != this.currentPage) {
          this.currentPage = this.pagination.currentPage;
        }
      },
      'pagination.perPage'() {
        if (this.pagination.perPage != this.pageSizeValue) {
          this.pageSizeValue = this.pagination.perPage;
        }
      }
    },
    created() {
      this.initAppTablePagination();
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.pagination.currentPage = this.pagination.currentPage;
      });
    },
    methods: {
      initAppTablePagination() {
        this.currentPage = this.pagination.currentPage;
        this.pageSizeValue = this.pagination.perPage;
      },
      updatePageSize(value) {
        this.pageSizeValue = value;

        let obj = {
          page: 1,
          size: value
        };

        this.updatePagination(obj);
      },
      changePage(value) {
        let obj = {
          page: value,
          size: this.pageSizeValue
        };

        this.updatePagination(obj);
      },
      updatePagination(obj) {
        this.$emit('change', obj);
      }
    }
  };
</script>

<style lang="scss">
  @import '@/assets/sass/style.vue';

  .app-pagination-pager {
    .page-item {
      padding: 0;
      margin-right: 5px;
      display: inline-block;
    }
    > .page {
      &.active {
        > .page-link {
          z-index: 2;
          color: $primary-light;
          text-decoration: none;
          background-color: $primary;
          border-color: none;
        }
      }

      > .page-link {
        min-width: 2.25rem;
        background-color: transparent;
        border: none;
        border-radius: 0.42rem;
        color: $gray-600;
        transition: $transition-link;
        &:hover {
          z-index: 2;
          color: $primary-light;
          text-decoration: none;
          background-color: $primary;
          border-color: none;
        }
      }
    }
    > .arrow-button {
      &.disabled {
        opacity: 0.5;
      }
      > .page-link {
        background-color: $gray-300;
        color: $gray-600;
        border-color: none;
        border-radius: 0.42rem;
        min-width: 2.25rem;
        &:hover {
          z-index: 2;
          color: $primary-light;
          text-decoration: none;
          background-color: $primary;
          border-color: none;
        }
      }
    }
  }

  .dropdown-new-class > .btn,
  .dropdown-menu-new-class.dropdown-menu {
    width: 4.5rem !important;
    min-width: 4.5rem !important;
  }

  .dropdown-new-class {
    &.show {
      > .btn {
        background-color: $primary !important;
        color: $primary-light !important;
        &::after {
          color: $primary-light !important;
        }
      }
    }
    > .btn {
      &:hover,
      &:active,
      &:focus {
        background-color: $primary !important;
        color: $primary-light !important;
        &::after {
          color: $primary-light !important;
        }
      }
    }
  }
</style>
